(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.referees.referee')
    .controller('RefereeCtrl', RefereeCtrl);

  function RefereeCtrl($mdToast, $scope, $state, genders, townHalls, entities, AclService, refereeLicense, $mdDialog, $filter, RefereeLicence) {
    var vm = this;
    vm.ctrlName = 'RefereeCtrl';
    vm.today = moment().toDate();

    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    vm.isAdmin = AclService.isAdmin;
    vm.edit = false;
    vm.personGenders = [];
    angular.forEach(genders, function(gender){
      if(gender.person) vm.personGenders.push(gender);
    });
    vm.entities = entities;
    vm.townHalls = townHalls;
    vm.license = angular.copy(refereeLicense);
    vm.person = angular.copy(vm.license.person);


    vm.updateLicense = updateLicense;
    vm.setRefereeToCouncil = setRefereeToCouncil;
    vm.setRefereeToTownhall = setRefereeToTownhall;
    vm.setRefereeToEntity = setRefereeToEntity;
    vm.initialState = {};
    vm.initialState.isTowhallSelectable = vm.isTowhallSelectable = false;
    vm.initialState.isEntitySelectable = vm.isEntitySelectable = false;
    vm.initialState.belongsToCouncil = vm.belongsToCouncil = true;
    vm.initialState.belongsToTownHall = vm.belongsToTownHall = false;
    vm.initialState.belongsToEntity = vm.belongsToEntity = false;


    if (!_.isNull(vm.license.organisationId) && angular.isDefined(vm.license.organisationId)) {
      vm.initialState.belongingTownHall = vm.belongingTownHall = angular.copy(findItemById(vm.townHalls, vm.license.organisationId));
      vm.initialState.belongingEntity = vm.belongingEntity = angular.copy(findItemById(vm.entities, vm.license.organisationId));
      vm.initialState.belongsToCouncil = vm.belongsToCouncil = false;
    }

    if (angular.isDefined(vm.belongingTownHall)) vm.initialState.belongsToTownHall = vm.belongsToTownHall = true;
    if (angular.isDefined(vm.belongingEntity)) vm.initialState.belongsToEntity = vm.belongsToEntity = true;

    function resetState() {
      vm.isTowhallSelectable = vm.initialState.isTowhallSelectable;
      vm.isEntitySelectable = vm.initialState.isEntitySelectable;
      vm.belongsToCouncil = vm.initialState.belongsToCouncil;
      vm.belongsToTownHall = vm.initialState.belongsToTownHall;
      vm.belongsToEntity = vm.initialState.belongsToEntity;
      vm.belongsToTownHall = vm.initialState.belongingTownHall;
      vm.belongingEntity = vm.initialState.belongingEntity
    }

    function setRefereeToCouncil() {
      vm.belongsToCouncil = true;
      vm.belongsToTownHall = false;
      vm.isTowhallSelectable = false;
      vm.selectedTownhall = undefined;
    }

    function setRefereeToTownhall() {
      vm.belongsToTownHall = true;
      vm.belongsToCouncil = false;
      vm.belongsToEntity = false;
      vm.isEntitySelectable = false;
      vm.selectedEntity = undefined;
      vm.isTowhallSelectable = true;
      if (angular.isDefined(vm.belongingTownHall)) {
        vm.selectedTownhall = vm.belongingTownHall;
        if (vm.townHalls.length === 1) {
          vm.isTowhallSelectable = false;
        }
      }
      else if (vm.townHalls.length === 1) {
        vm.selectedTownhall = vm.townHalls[0];
        vm.isTowhallSelectable = false;
      }
    }

    function setRefereeToEntity() {
      vm.belongsToEntity = true;
      vm.belongsToTownHall = false;
      vm.selectedTownhall = undefined;
      vm.isEntitySelectable = true;
      if (angular.isDefined(vm.belongingEntity)) {
        vm.selectedEntity = vm.belongingEntity;
        if (vm.entities.length === 1) {
          vm.isEntitySelectable = false;
        }
      }
      else if (vm.entities.length === 1) {
        vm.selectedEntity = vm.entities[0];
        vm.isEntitySelectable = false;
      }
    }

    vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'ACTIONS',
      actions: [{
        icon: 'edit',
        tooltip: 'EDIT',
        execute: enableEdit
      }, {
        icon: 'delete',
        attrClass: 'md-warn',
        tooltip: 'DELETE',
        execute: deleteLicence
      }, {
        icon: 'timer',
       
        tooltip: 'Partits arbitrats',
        execute: partitsarb
      }]
    };
    function partitsarb(){
         $state.go('home.refereeNewInvoice', {licenseId: vm.license.id});
    }
    function enableEdit() {
      if (vm.belongsToCouncil) setRefereeToCouncil();
      else if (vm.belongsToTownHall) setRefereeToTownhall();
      else setRefereeToEntity();
      vm.edit = true;
      vm.buttons.dial = false;
    }

    function deleteLicence() {
      RefereeLicence.remove({licenceId: refereeLicense.id}, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('REFEREES.SUCCESSFUL_DELETE'))
              .position('bottom left')
              .hideDelay(3000));
          $state.go('home.referees');
        },
        function (err) {
          var message = err.data.message || 'PEOPLE.ERROR_DELETE';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    vm.disableEdit = function () {
      resetState();
      vm.edit = false;
      vm.license = angular.copy(refereeLicense);
      vm.person = angular.copy(vm.license.person);
      vm.buttons.dial = true;
      $scope.refereeForm.$setUntouched();
      $scope.refereeForm.$setPristine();
      $scope.refereeForm.$setValidity();
    };

    function getSelectedOrganisationId() {
      if (vm.belongsToCouncil) return null;
      if (vm.belongsToTownHall) return vm.selectedTownhall.id;
      else return vm.selectedEntity.id;
    }

    function updateLicense() {
      var selectedOrganisationId = getSelectedOrganisationId();
      if (selectedOrganisationId !== refereeLicense.organisationId) {

        var confirm = $mdDialog.confirm()
          .title($filter('translate')('ALERT'))
          .textContent($filter('translate')('REFEREES.MODIFY_BELONGING_CONFIRMATION'))
          .ok($filter('translate')('YES'))
          .cancel($filter('translate')('NO'));

        $mdDialog.show(confirm).then(function () {
          callEdit();
        }, function () {
          $mdDialog.cancel();
        })
      }
      else {
        callEdit();
      }
    }

    function callEdit() {
      var newRefereeLicensePutObject = {
        organisationId: getSelectedOrganisationId(),
        iban: vm.license.iban,
        person: {
          id: vm.person.id,
          name: vm.person.name,
          surname: vm.person.surname,
          secondSurname: vm.person.secondSurname,
          genderId: vm.person.genderId,
          bornDate: parseDate(vm.person.bornDate),
          nif: vm.person.nif,
          healthInsuranceCardNumber: vm.person.healthInsuranceCardNumber,
          email: vm.person.email,
          address: vm.person.address,
          town: vm.person.town,
          zipCode: vm.person.zipCode
        }
      };
      RefereeLicence.update({licenceId: refereeLicense.id}, newRefereeLicensePutObject, vm.successfulPersonEdition, vm.errorInPersonAddition);
    }

    vm.successfulPersonEdition = function () {
      $state.go('home.referees', {reload: true});
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('PEOPLE.SUCCESSFUL_UPDATE'))
          .position('bottom left')
          .hideDelay(3000)
      );
    };


    function parseDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
